
.ant-collapse .filterPanel {
  border-bottom: 0px;
  background-color: white;
  .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse-content {
    margin: 5px;
    padding: 15px;
    border: 1px solid #e6f7ff;
    background-color: #e6f7ff;
  }
}
.ReactCollapse--collapse {
  transition: height 500ms;
}