@media screen and (max-width: 400px) {
  .context {
    width: 90vw ;
    min-width: 320px;
  }
}

@media screen and (min-width: 400px) and (max-width: 800px) {
  .context {
    width: 70vw;
  }
}

@media screen and (min-width: 800px) {
  .context {
    width: 60vw;
  }
}